import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import {
  addSequenceStep,
  addSequenceStepVariant,
  cloneSequence,
  createSequence,
  deleteSequence,
  deleteSequences,
  deleteSequenceStep,
  deleteSequenceStepVariant,
  getSequence,
  getSequences,
  pauseResumeSequence,
  renameSequence,
  updateSequenceSettings,
  updateSequenceStepExecutionDay,
  updateSequenceStepVariantData,
  updateSequenceStepVariantStatus,
  getSequenceSteps,
  addContactsFromCSV,
  getAllSchedules,
  getConnectedEmails,
  getContactStatusCount,
  getSequenceContactList,
  changeStatusOfContactsOfSequence,
  getContactSequenceList,
  getContactStepList,
  addContactsToStep,
  removeContactFromSequence,
  addContactsFromCSVWithoutStep,
  getAdminSettings,
  uploadImage,
  finishOnboarding,
  getSequenceMailSendingStatus,
  getSequenceStats,
  getSequencesStats,
  getSequenceConfig,
  createSampleSteps,
  addSampleProspectsToStep,
  getTemplates,
  getEmailStepsOfStatus,
  getEmailStatusOfStep,
  getEmailList,
  sendScheduledEmail,
  changeEmailStatus,
  getSequenceProspectActivity,
  getCustomDomains,
  getSequenceProspectVerificationStats,
  sendSequenceProspectVerifyStart,
  uploadAttachment,
  deleteAttachment,
  getSentEmailPreview,
  createEmailWritingFactors,
  updateEmailWritingFactors,
  getSequenceScore,
  getLinkedEmailAccounts,
  linkEmailToSequence,
  getAllNotLinkedEmailAccounts,
  removeConnectedEmailAccount,
  getNotLinkedEmailAccountsCount,
  getSenderEmails,
  resendFailedEmail,
  updateSequenceStep,
  onOutcomeChange,
  bulkPauseSequence,
  bulkResumeSequence,
  getTaskAssigneeList,
  getAllConnectedEmailAccounts,
  getSequenceTimezone,
  cloneSequenceStepVariant,
  sequenceProspectBulkStatusUpdate,
  sequenceProspectBulkTagAssign,
  sequenceProspectBulkTagUnAssign,
  sequenceProspectBulkAddToSequence,
  sequenceProspectBulkRemoveFromSequence,
  getSequenceStatsCounts,
} from './helpers/sequence.api';
import {
  AddContactsFromCSVRequestPayload,
  AddSequenceStepRequestPayload,
  AddSequenceStepVariantRequestPayload,
  ContactListParams,
  CreateSequenceRequestPayload,
  DeleteSequencesRequestPayload,
  GetSequencesQueryParams,
  PauseResumeSequenceRequestPayload,
  RenameSequenceRequestPayload,
  UpdateSequenceSettingsRequestPayload,
  UpdateSequenceStepExecutionDayRequestPayload,
  UpdateSequenceStepVariantDataRequestPayload,
  ChangeSequenceContactStatusPayload,
  UpdateSequenceStepVariantStatusRequestPayload,
  AddToStepParams,
  ImageUploadPayloadWithProgressCallback,
  GetSequenceProspectActivityPayload,
  AttachmentUploadPayload,
  AttachmentDeletePayload,
  EmailWritingFactorsPayload,
  GetSequenceTimezonePayload,
  SequenceProspectBulkStatusUpdatePayload,
  SequenceProspectBulkTagAssignPayload,
  SequenceProspectBulkTagUnAssignPayload,
  SequenceProspectBulkAddToSequencePayload,
  SequenceProspectBulkRemoveFromSequencePayload,
} from './types';
import { getContactFields } from '../settings/helpers/field.api';
import { ProspectCountParams, ProspectParams } from '../prospect/types';
import {
  getProspect,
  getProspectCount,
} from '../prospect/helpers/prospect.api';
import { EmailStatus, GetEmailRequestPayload } from './types/emails';
import { GetTemplatesRequest } from '../templates/types/request';

// Sequence

export const getSequenceRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSequenceRequest', async (sequenceId, thunkAPI) => {
  try {
    return await getSequence(sequenceId);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getSequencesRequest = createAsyncThunk<
  ResponseSuccess,
  GetSequencesQueryParams,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSequencesRequest', async (args, thunkAPI) => {
  try {
    return await getSequences(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getSequenceStatsRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSequenceStatsRequest', async (sequenceId, thunkAPI) => {
  try {
    return await getSequenceStats(sequenceId);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getSequencesStatsRequest = createAsyncThunk<
  ResponseSuccess,
  GetSequencesQueryParams,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSequencesStatsRequest', async (args, thunkAPI) => {
  try {
    return await getSequencesStats(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const createSequenceRequest = createAsyncThunk<
  ResponseSuccess,
  CreateSequenceRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/createSequenceRequest', async (args, thunkAPI) => {
  try {
    return await createSequence(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

type RenameSequenceRequestPayloadCreatorArgs = RenameSequenceRequestPayload & {
  sequenceId: number;
};

export const renameSequenceRequest = createAsyncThunk<
  ResponseSuccess,
  RenameSequenceRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/renameSequenceRequest',
  async ({ sequenceId, ...rest }, thunkAPI) => {
    try {
      return await renameSequence(sequenceId, rest);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

type PauseResumeSequenceRequestPayloadCreatorArgs = PauseResumeSequenceRequestPayload & {
  sequenceId: number;
};

export const pauseResumeSequenceRequest = createAsyncThunk<
  ResponseSuccess,
  PauseResumeSequenceRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/pauseResumeSequenceRequest',
  async ({ sequenceId, ...rest }, thunkAPI) => {
    try {
      return await pauseResumeSequence(sequenceId, rest);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const cloneSequenceRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/cloneSequenceRequest', async (sequenceId, thunkAPI) => {
  try {
    return await cloneSequence(sequenceId);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const deleteSequenceRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/deleteSequenceRequest', async (sequenceId, thunkAPI) => {
  try {
    return await deleteSequence(sequenceId);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const deleteSequencesRequest = createAsyncThunk<
  ResponseSuccess,
  DeleteSequencesRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/deleteSequencesRequest', async (args, thunkAPI) => {
  try {
    return await deleteSequences(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

// Step

type AddSequenceStepRequestPayloadCreatorArgs = AddSequenceStepRequestPayload & {
  sequenceId: number;
};

export const addSequenceStepRequest = createAsyncThunk<
  ResponseSuccess,
  AddSequenceStepRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/addSequenceStepRequest',
  async ({ sequenceId, ...rest }, thunkAPI) => {
    try {
      return await addSequenceStep(sequenceId, rest);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

type UpdateSequenceStepExecutionDayRequestPayloadCreatorArgs = UpdateSequenceStepExecutionDayRequestPayload & {
  sequenceId: number;
  stepId: number;
};

export const updateSequenceStepExecutionDayRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateSequenceStepExecutionDayRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/updateSequenceStepExecutionDayRequest',
  async ({ sequenceId, stepId, ...rest }, thunkAPI) => {
    try {
      return await updateSequenceStepExecutionDay(sequenceId, stepId, rest);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const updateSequenceStepRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateSequenceStepExecutionDayRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/updateSequenceStepRequest',
  async ({ sequenceId, stepId, ...rest }, thunkAPI) => {
    try {
      return await updateSequenceStep(sequenceId, stepId, rest);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

type DeleteSequenceStepRequestPayloadCreatorArgs = {
  sequenceId: number;
  stepId: number;
};

export const deleteSequenceStepRequest = createAsyncThunk<
  ResponseSuccess,
  DeleteSequenceStepRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/deleteSequenceStepRequest',
  async ({ sequenceId, stepId }, thunkAPI) => {
    try {
      return await deleteSequenceStep(sequenceId, stepId);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

type AddSequenceStepVariantRequestPayloadCreatorArgs = AddSequenceStepVariantRequestPayload & {
  sequenceId: number;
  stepId: number;
};

export const addSequenceStepVariantRequest = createAsyncThunk<
  ResponseSuccess,
  AddSequenceStepVariantRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/addSequenceStepVariantRequest',
  async ({ sequenceId, stepId, ...rest }, thunkAPI) => {
    try {
      return await addSequenceStepVariant(sequenceId, stepId, rest);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

type UpdateSequenceStepVariantDataRequestPayloadCreatorArgs = UpdateSequenceStepVariantDataRequestPayload & {
  sequenceId: number;
  stepId: number;
  variantId: number;
};

export const updateSequenceStepVariantDataRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateSequenceStepVariantDataRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/updateSequenceStepVariantDataRequest',
  async ({ sequenceId, stepId, variantId, ...rest }, thunkAPI) => {
    try {
      return await updateSequenceStepVariantData(
        sequenceId,
        stepId,
        variantId,
        rest,
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

type UpdateSequenceStepVariantStatusRequestPayloadCreatorArgs = UpdateSequenceStepVariantStatusRequestPayload & {
  sequenceId: number;
  stepId: number;
  variantId: number;
};

export const updateSequenceStepVariantStatusRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateSequenceStepVariantStatusRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/updateSequenceStepVariantStatusRequest',
  async ({ sequenceId, stepId, variantId, ...rest }, thunkAPI) => {
    try {
      return await updateSequenceStepVariantStatus(
        sequenceId,
        stepId,
        variantId,
        rest,
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// Create a generic type for sequenceId, stepId and variantId.
// just like SequenceIdParamDto, etc. in backend
type DeleteSequenceStepVariantRequestPayloadCreatorArgs = {
  sequenceId: number;
  stepId: number;
  variantId: number;
};

export const deleteSequenceStepVariantRequest = createAsyncThunk<
  ResponseSuccess,
  DeleteSequenceStepVariantRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/deleteSequenceStepVariantRequest',
  async ({ sequenceId, stepId, variantId }, thunkAPI) => {
    try {
      return await deleteSequenceStepVariant(sequenceId, stepId, variantId);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const cloneSequenceStepVariantRequest = createAsyncThunk<
  ResponseSuccess,
  { variantId: number },
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/cloneSequenceStepVariantRequest',
  async ({ variantId }, thunkAPI) => {
    try {
      return await cloneSequenceStepVariant(variantId);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

type UpdateSequenceSettingsRequestPayloadCreatorArgs = UpdateSequenceSettingsRequestPayload & {
  sequenceId: string;
};

export const updateSequenceSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateSequenceSettingsRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/updateSequenceSettingsRequest',
  async ({ sequenceId, ...rest }, thunkAPI) => {
    try {
      return await updateSequenceSettings(sequenceId, rest);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getContactFieldsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getContactFieldsRequest', async (args, thunkAPI) => {
  try {
    return await getContactFields();
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getSequenceStepsRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSequenceStepsRequest', async (sequenceId, thunkAPI) => {
  try {
    return await getSequenceSteps(sequenceId);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

type AddContactsFromCSVRequestPayloadCreatorArgs = AddContactsFromCSVRequestPayload & {
  stepId: number;
};

export const addContactsFromCSVRequest = createAsyncThunk<
  ResponseSuccess,
  AddContactsFromCSVRequestPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/addContactsFromCSVRequest', async ({ ...rest }, thunkAPI) => {
  try {
    return await addContactsFromCSV(rest);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const addContactsFromCSVRequestWithoutStep = createAsyncThunk<
  ResponseSuccess,
  AddContactsFromCSVRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/addContactsFromCSVRequest', async ({ ...rest }, thunkAPI) => {
  try {
    return await addContactsFromCSVWithoutStep(rest);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getSchedulesRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSchedules', async (args, thunkAPI) => {
  try {
    return await getAllSchedules();
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getConnectedEmailsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getConnectedEmails', async (args, thunkAPI) => {
  try {
    return await getConnectedEmails();
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

type GetContactStatusCountRequestPayloadArgsCreator = ContactListParams & {
  sequenceId: string;
  includeAllFields?: boolean;
};

// Contact Status Count Request
export const getContactStatusCountRequest = createAsyncThunk<
  ResponseSuccess,
  GetContactStatusCountRequestPayloadArgsCreator,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/getContactStatusCount',
  async ({ sequenceId, ...params }, thunkAPI) => {
    try {
      return await getContactStatusCount(sequenceId, params);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// Change sequence contacts status.
export const changeStatusOfContacts = createAsyncThunk<
  ResponseSuccess,
  ChangeSequenceContactStatusPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/changeStatusOfContacts',
  async ({ contactAndStepIds, status, pauseDelayInDays }, thunkAPI) => {
    try {
      return await changeStatusOfContactsOfSequence(
        status,
        contactAndStepIds,
        pauseDelayInDays,
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// Get Contact Sequence List.
export const getContactSequenceListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getContactSequenceListRequest', async (args, thunkAPI) => {
  try {
    return await getContactSequenceList();
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

// Get Contact Step List.
export const getContactSequenceStepListRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getContactSequenceStepListRequest', async (args, thunkAPI) => {
  try {
    return await getContactStepList(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

// Add Contact to Sequence's Step
export const addContactsToStepRequest = createAsyncThunk<
  ResponseSuccess,
  AddToStepParams,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/addContactsToStepRequest',
  async ({ stepId, sequenceId, contactIds }, thunkAPI) => {
    try {
      return await addContactsToStep({ stepId, sequenceId, contactIds });
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// Remove Contacts from Sequences
export const removeContactFromSequenceRequest = createAsyncThunk<
  ResponseSuccess,
  AddToStepParams,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/removeContactFromSequenceRequest',
  async ({ sequenceId, contactIds }, thunkAPI) => {
    try {
      return await removeContactFromSequence(sequenceId, contactIds);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// Get sequence List based on filter
type GetSequenceContactListArgsCreator = GetContactStatusCountRequestPayloadArgsCreator;

export const getSequenceContactListRequest = createAsyncThunk<
  ResponseSuccess,
  GetSequenceContactListArgsCreator,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/getSequenceContactList',
  async ({ sequenceId, ...params }, thunkAPI) => {
    try {
      return await getSequenceContactList(sequenceId, params);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const imageUploadRequest = createAsyncThunk<
  ResponseSuccess,
  ImageUploadPayloadWithProgressCallback,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/imageUploadRequest',
  async ({ formData, onUploadProgress }, thunkAPI) => {
    try {
      return await uploadImage(formData, onUploadProgress);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getProspectRequestModal = createAsyncThunk<
  ResponseSuccess,
  ProspectParams,
  { rejectValue: ResponseErrorWithHandled }
>('contact/getProspectRequestModal', async (args: ProspectParams, thunkAPI) => {
  try {
    return await getProspect(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getAdminSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>(
  'adminSettings/getAdminSettingsRequest',
  async (args: ProspectParams, thunkAPI) => {
    try {
      return await getAdminSettings();
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getProspectCountRequestModal = createAsyncThunk<
  ResponseSuccess,
  ProspectCountParams,
  { rejectValue: ResponseErrorWithHandled }
>(
  'contact/getProspectCountRequestModal',
  async (args: ProspectCountParams, thunkAPI) => {
    try {
      return await getProspectCount(args);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const addToStepModalRequest = createAsyncThunk<
  ResponseSuccess,
  AddToStepParams,
  { rejectValue: ResponseErrorWithHandled }
>('contacts/addToStepModal', async (payload, thunkAPI) => {
  try {
    return await addContactsToStep(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const sequenceSendEmailStatus = createAsyncThunk<
  ResponseSuccess,
  { sequenceId: string },
  { rejectValue: ResponseErrorWithHandled }
>('sequence/sequenceSendEmailStatus', async ({ sequenceId }, thunkAPI) => {
  try {
    return await getSequenceMailSendingStatus(sequenceId);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const finishOnboardingRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/finishOnboardingRequest', async (args, thunkAPI) => {
  try {
    return await finishOnboarding();
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getSequenceConfigRequest = createAsyncThunk<
  ResponseSuccess,
  { sequenceId: string },
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSequenceConfigRequest', async ({ sequenceId }, thunkAPI) => {
  try {
    return await getSequenceConfig(sequenceId);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Create Sample Steps
export const createSampleStepsRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/createSampleStepsRequest', async (args, thunkAPI) => {
  try {
    return await createSampleSteps(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Create Sample Steps
export const addSampleProspectsToStepRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/addSampleProspectsToStep', async (args, thunkAPI) => {
  try {
    return await addSampleProspectsToStep(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// My Template
export const getMyTemplateRequest = createAsyncThunk<
  ResponseSuccess,
  GetTemplatesRequest,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getMyTemplates', async (args, thunkAPI) => {
  try {
    return await getTemplates(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Team Template
export const getTeamTemplateRequest = createAsyncThunk<
  ResponseSuccess,
  GetTemplatesRequest,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getTeamTemplates', async (args, thunkAPI) => {
  try {
    return await getTemplates(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Email tab

// Get steps list of status
export const getEmailStepOfStatusRequest = createAsyncThunk<
  ResponseSuccess,
  {
    sequenceId: string;
    args: {
      status: EmailStatus;
      search: string;
      emailAccountIds?: string;
      variantNumbers?: string;
      sentAt?: any;
    };
  },
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getEmailStepsOfStatus', async ({ sequenceId, args }, thunkAPI) => {
  try {
    return await getEmailStepsOfStatus(sequenceId, args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Get list of status with step
export const getEmailStatusOfStepRequest = createAsyncThunk<
  ResponseSuccess,
  {
    sequenceId: string;
    args: {
      stepId: number | null;
      search: string;
      emailAccountIds?: string;
      variantNumbers?: string;
      sentAt?: any;
    };
  },
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getEmailStatusOfStep', async ({ sequenceId, args }, thunkAPI) => {
  try {
    return await getEmailStatusOfStep(sequenceId, args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Get emails list
export const getEmailListRequest = createAsyncThunk<
  ResponseSuccess,
  GetEmailRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getEmailsList', async (args, thunkAPI) => {
  try {
    return await getEmailList(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const sendScheduledEmailRequest = createAsyncThunk<
  ResponseSuccess,
  any,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/sendScheduledEmail', async (args, thunkAPI) => {
  try {
    return await sendScheduledEmail(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const changeEmailStatusRequest = createAsyncThunk<
  ResponseSuccess,
  any,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/changeEmailStatus', async (args, thunkAPI) => {
  try {
    return await changeEmailStatus(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSequenceProspectActivityRequest = createAsyncThunk<
  ResponseSuccess,
  GetSequenceProspectActivityPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/getSequenceProspectActivity',
  async ({ sequenceId, prospectId }, thunkAPI) => {
    try {
      return await getSequenceProspectActivity(sequenceId, prospectId);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getCustomDomainsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('customDomain/getCustomDomainsRequest', async (args, thunkAPI) => {
  try {
    return await getCustomDomains();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSequenceProspectVerificationStatsRequest = createAsyncThunk<
  ResponseSuccess,
  any,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSequenceProspectVerificationStats', async (args, thunkAPI) => {
  try {
    return await getSequenceProspectVerificationStats(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const sendSequenceProspectVerifyStartRequest = createAsyncThunk<
  ResponseSuccess,
  any,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/sendSequenceProspectVerifyStart', async (args, thunkAPI) => {
  try {
    return await sendSequenceProspectVerifyStart(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Upload Attachment Request
export const uploadAttachmentRequest = createAsyncThunk<
  ResponseSuccess,
  AttachmentUploadPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/uploadAttachmentRequest',
  async ({ formData, onUploadProgress, cancelToken }, thunkAPI) => {
    try {
      return await uploadAttachment(formData, onUploadProgress, cancelToken);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// Delete Attachment Request
export const deleteAttachmentRequest = createAsyncThunk<
  ResponseSuccess,
  AttachmentDeletePayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/deleteAttachmentRequest',
  async (
    { attachmentId, deleteFromTemplate, sequenceStepVariantId },
    thunkAPI,
  ) => {
    try {
      return await deleteAttachment(
        attachmentId,
        deleteFromTemplate,
        sequenceStepVariantId,
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const getSentEmailPreviewRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSentEmailPreview', async (args, thunkAPI) => {
  try {
    return await getSentEmailPreview(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSequenceScoreRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSequenceScore', async (args, thunkAPI) => {
  try {
    return await getSequenceScore(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createEmailWritingFactorsRequest = createAsyncThunk<
  ResponseSuccess,
  EmailWritingFactorsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/createEmailWritingFactors', async (args, thunkAPI) => {
  try {
    return await createEmailWritingFactors(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateEmailWritingFactorsRequest = createAsyncThunk<
  ResponseSuccess,
  EmailWritingFactorsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/updateEmailWritingFactors', async (args, thunkAPI) => {
  try {
    return await updateEmailWritingFactors(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getLinkedEmailAccountsRequest = createAsyncThunk<
  ResponseSuccess,
  { sequenceId: string; pageNum: number },
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/getLinkedEmailAccounts',
  async ({ sequenceId, pageNum }, thunkAPI) => {
    try {
      return await getLinkedEmailAccounts(sequenceId, pageNum);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const linkEmailToSequenceRequest = createAsyncThunk<
  ResponseSuccess,
  { sequenceId: string; emailAccountIds: number[] },
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/linkEmailToSequence',
  async ({ sequenceId, emailAccountIds }, thunkAPI) => {
    try {
      return await linkEmailToSequence(sequenceId, emailAccountIds);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getAllNotLinkedEmailAccountsRequest = createAsyncThunk<
  ResponseSuccess,
  { sequenceId: string; clientIds: string; search: string; pageNum: number },
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/getAllNotLinedEmailAccount',
  async ({ sequenceId, clientIds, search, pageNum }, thunkAPI) => {
    try {
      return await getAllNotLinkedEmailAccounts(
        sequenceId,
        clientIds,
        search,
        pageNum,
      );
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const removeConnectedEMailAccountRequest = createAsyncThunk<
  ResponseSuccess,
  { sequenceId: string; emailAccountId: number },
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/removeConnectedEmailAccount',
  async ({ sequenceId, emailAccountId }, thunkAPI) => {
    try {
      return await removeConnectedEmailAccount(sequenceId, emailAccountId);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getNotLinkedEmailAccountsCountRequest = createAsyncThunk<
  ResponseSuccess,
  { sequenceId: string; search: string; clientIds: string },
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/notLinkedEmailAccountCount',
  async ({ sequenceId, search, clientIds }, thunkAPI) => {
    try {
      return await getNotLinkedEmailAccountsCount(
        sequenceId,
        search,
        clientIds,
      );
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getSenderEmailsRequest = createAsyncThunk<
  ResponseSuccess,
  { sequenceId: string },
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSenderEmailRequest', async ({ sequenceId }, thunkAPI) => {
  try {
    return await getSenderEmails(sequenceId);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const resendFailedEmailRequest = createAsyncThunk<
  ResponseSuccess,
  any,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/resendFailedEmail', async (args, thunkAPI) => {
  try {
    return await resendFailedEmail(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const onOutcomeChangeRequest = createAsyncThunk<
  ResponseSuccess,
  { sequenceId: number; prospectId: number; outcomeId: number },
  { rejectValue: ResponseErrorWithHandled }
>(
  'sequence/onOutcomeChangeRequest',
  async ({ sequenceId, prospectId, outcomeId }, thunkAPI) => {
    try {
      return await onOutcomeChange(sequenceId, prospectId, outcomeId);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getTaskAssigneeListRequest = createAsyncThunk<
  ResponseSuccess,
  { userId: number },
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getTaskAssigneeList', async ({ userId }, thunkAPI) => {
  try {
    return await getTaskAssigneeList(userId);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const bulkPauseSequenceRequest = createAsyncThunk<
  ResponseSuccess,
  number[],
  { rejectValue: ResponseErrorWithHandled }
>('sequence/bulkPauseSequence', async (args, thunkAPI) => {
  try {
    return await bulkPauseSequence(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const bulkResumeSequenceRequest = createAsyncThunk<
  ResponseSuccess,
  number[],
  { rejectValue: ResponseErrorWithHandled }
>('sequence/bulkResumeSequence', async (args, thunkAPI) => {
  try {
    return await bulkResumeSequence(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getAllConnectedEmailAccountsRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getAllConnectedEmailAccounts', async (args, thunkAPI) => {
  try {
    return await getAllConnectedEmailAccounts(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSequenceTimezoneRequest = createAsyncThunk<
  ResponseSuccess,
  GetSequenceTimezonePayload,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSequenceTimezone', async ({ sequenceId }, thunkAPI) => {
  try {
    return await getSequenceTimezone(sequenceId);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const sequenceProspectBulkStatusUpdateRequest = createAsyncThunk<
  ResponseSuccess,
  SequenceProspectBulkStatusUpdatePayload,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/sequenceProspectBulkStatusUpdate', async (args, thunkAPI) => {
  try {
    return await sequenceProspectBulkStatusUpdate(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const sequenceProspectBulkTagAssignRequest = createAsyncThunk<
  ResponseSuccess,
  SequenceProspectBulkTagAssignPayload,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/sequenceProspectBulkTagAssign', async (args, thunkAPI) => {
  try {
    return await sequenceProspectBulkTagAssign(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const sequenceProspectBulkTagUnAssignRequest = createAsyncThunk<
  ResponseSuccess,
  SequenceProspectBulkTagUnAssignPayload,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/sequenceProspectBulkTagUnAssign', async (args, thunkAPI) => {
  try {
    return await sequenceProspectBulkTagUnAssign(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const sequenceProspectBulkAddToSequenceRequest = createAsyncThunk<
  ResponseSuccess,
  SequenceProspectBulkAddToSequencePayload,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/sequenceProspectBulkAddToSequence', async (args, thunkAPI) => {
  try {
    return await sequenceProspectBulkAddToSequence(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const sequenceProspectBulkRemoveFromSequenceRequest = createAsyncThunk<
  ResponseSuccess,
  SequenceProspectBulkRemoveFromSequencePayload,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/sequenceProspectBulkRemoveFromSequence', async (args, thunkAPI) => {
  try {
    return await sequenceProspectBulkRemoveFromSequence(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSequenceStatsCountsRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('sequence/getSequenceStatsCountsRequest', async (sequenceId, thunkAPI) => {
  try {
    return await getSequenceStatsCounts(sequenceId);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
