import { useState, useEffect } from 'react';

export const useLeadBulkAction = (leadsData, selectedLeads, setSelectedLeads) => {
  const [disabledLeads, setDisabledLeads] = useState([]);
  const [isBulkRevealing, setIsBulkRevealing] = useState(false);
  const [isSelectAllIndeterminate, setIsSelectAllIndeterminate] = useState(
    false,
  );

  useEffect(() => {
    const isAllSelected =
      leadsData?.profiles?.length > 0 &&
      leadsData?.profiles?.every(
        (lead) =>
          selectedLeads?.some((l) => l?.id === lead?.id) &&
          !disabledLeads?.some((dLead) => dLead?.id === lead?.id),
      );

    const isSomeSelected =
      leadsData?.profiles?.some(
        (lead) =>
          selectedLeads?.some((l) => l?.id === lead?.id) &&
          !disabledLeads?.some((dLead) => dLead?.id === lead?.id),
      ) && !isAllSelected;

    setIsSelectAllIndeterminate(isSomeSelected);
  }, [selectedLeads, disabledLeads, leadsData]);

  const handleCheckUnCheckSingleLead = (lead) => {
    const isLeadAdded = selectedLeads?.find((l) => l?.id === lead?.id);
    const isLeadDisabled = disabledLeads?.some(
      (dLead) => dLead?.id === lead?.id,
    );

    if (!isLeadAdded && !isLeadDisabled) {
      setSelectedLeads((prevState) => [...prevState, lead]);
    } else {
      setSelectedLeads((prevState) =>
        prevState.filter((l) => l?.id !== lead?.id),
      );
    }
  };

  const handleSelectAllLead = (leadProfiles = []) => {
    const isAllSelected = leadProfiles?.every(
      (lead) =>
        selectedLeads?.some((l) => l?.id === lead?.id) &&
        !disabledLeads?.some((dLead) => dLead?.id === lead?.id),
    );

    const leadProfileArrIds = new Set(leadProfiles?.map((item) => item?.id));
    const filteredSelectedLeadArr = selectedLeads?.filter(
      (item) =>
        !leadProfileArrIds?.has(item?.id) &&
        !disabledLeads?.some((dLead) => dLead?.id === item?.id),
    );

    if (isAllSelected) {
      setSelectedLeads(filteredSelectedLeadArr);
    } else {
      const filteredLeadProfiles = leadProfiles?.filter(
        (lead) => !disabledLeads?.some((dLead) => dLead?.id === lead?.id),
      );
      setSelectedLeads(() => [
        ...filteredSelectedLeadArr,
        ...filteredLeadProfiles,
      ]);
    }
  };

  useEffect(() => {
    setDisabledLeads(
      leadsData?.profiles?.filter(
        (l) => l?.isRevealed && l?.emails?.length === 0,
      ),
    );
  }, [leadsData]);

  return {
    handleCheckUnCheckSingleLead,
    selectedLeads,
    handleSelectAllLead,
    setSelectedLeads,
    isBulkRevealing,
    setIsBulkRevealing,
    disabledLeads,
    setDisabledLeads,
    isSelectAllIndeterminate,
  };
};
