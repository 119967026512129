import React, { memo } from 'react';
import TagAutoSuggest from '../tag-autosuggest';
import { RenderMode } from '../tag-autosuggest/tag-autosuggest';
import { Images } from '../../../../app-constants';

const TagProspectsContent = ({ tags, tagsToAdd, setTagsToAdd }) => {
  return (
    <div className="review-section-container mt-2">
      <div className="review-section-title-container">
        <img src={Images.Tag} />
        <span className="review-section-title">{'Tag Prospects'}</span>
      </div>
      <div className="mt-n2">
        <TagAutoSuggest
          renderMode={RenderMode.Inline}
          tags={tags}
          setTagsToAdd={setTagsToAdd}
          tagsToAdd={tagsToAdd}
          hideAlreadyAddedTags={true}
        />
      </div>
    </div>
  );
};

export default memo(TagProspectsContent);
