import { ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { addToStepModalRequest } from '../../../components/sequence/extra-actions';
import {
  getFieldsRequest,
  addProspectManuallyRequest,
} from '../../../components/prospect/extra-actions';
import { AddProspectManuallyPayload } from '../../../components/prospect/types';
import { RootState } from '../../../store/root-reducer';
import { AddToStepParams } from '../../../components/sequence/types';
// eslint-disable-next-line import/no-cycle
import AddProspectForm from './add-prospect-form';
import { resetAddToProspectForm } from '../../../components/prospect/prospect-slice';
import updateProfileProgressRequest from '../getting-started-onboarding/extra-actions';
import { UpdateProfileProgressPayload } from '../../types/user-setting';
import { getUserSettingsRequest } from '../../../components/home/home-slice';

const mapStateToProps = (state: RootState) => ({
  getFieldsRequestStatus: state.prospect.getFieldsRequest.status,
  addProspectRequestStatus: state.prospect.addProspectManuallyRequest.status,
  addProspectRequestMessage: state.prospect.addProspectManuallyRequest.message,
  addProspectRequestError: state.prospect.addProspectManuallyRequest.error,
  fields: state.prospect.fields,
  addProspectResponse: state.prospect.addProspectResponse,

  getUserSettingsRequestStatus: state.home.getUserSettingsRequest.status,
  profileProgress: state.home.profileProgress,

  updateProfileProgressRequestStatus:
    state.home.updateProfileProgressRequest.status,
  updateProfileProgressRequestMessage:
    state.home.updateProfileProgressRequest.message,
  updateProfileProgressRequestError:
    state.home.updateProfileProgressRequest.error,
});

const mapDispatchToProps = {
  sendGetFieldsRequest: () => getFieldsRequest(),
  sendAddProspectRequest: ({ attributes }: AddProspectManuallyPayload) =>
    addProspectManuallyRequest({ attributes }),
  sendAddContactToStepRequest: (payload: AddToStepParams) =>
    addToStepModalRequest(payload),
  resetAddToProspectForm: () => resetAddToProspectForm(),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  updateProfileProgressRequest: (payload: UpdateProfileProgressPayload) =>
    updateProfileProgressRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  onClose?: () => void;
  onSubmit?: () => void;
  show?: boolean;
  containerClasses?: string;
  buttonTitle?: string;
  stepId?: number;
  sequenceId?: string;
  shouldSendAddToContactStepRequest?: boolean;
  content?: ReactNode;
};

export default connector(AddProspectForm);
