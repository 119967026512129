import { createAsyncThunk } from '@reduxjs/toolkit';
import { AddTagsToLeadsPayload, LeadsFindRequestPayload } from './type';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import { addTagsToLeads, getLeads, getTagsToLeads } from './helpers/leads.api';

export const getLeadsListRequest = createAsyncThunk<
  ResponseSuccess,
  { LeadsFindRequestPayload: LeadsFindRequestPayload },
  { rejectValue: ResponseErrorWithHandled }
  // eslint-disable-next-line @typescript-eslint/no-shadow
>('leads/getLeadList', async ({ LeadsFindRequestPayload }, thunkAPI) => {
  try {
    return await getLeads(LeadsFindRequestPayload);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const addTagsToLeadsRequest = createAsyncThunk<
  ResponseSuccess,
  AddTagsToLeadsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('leads/addTagsToLeadsRequest', async (args, thunkAPI) => {
  try {
    return await addTagsToLeads(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getTagsToLeadsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('leads/getTagsToLeads', async (args, thunkAPI) => {
  try {
    return await getTagsToLeads();
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
