/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable default-case */
/* eslint-disable no-else-return */
import React, { useEffect, useRef, useState } from 'react';
import {
  AlertCircle,
  Diamond,
  Download,
  Send,
  UserList,
} from '@saleshandy/icons';
import { Button } from '@saleshandy/design-system';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Checkbox from '../../../../shared/design-system/components/atoms/checkbox';
import AddToSequenceModal from '../../../prospect/components/prospect-list/components/modals/add-to-sequence-modal';
import GetContactDetail from '../../../prospect/components/prospect-list/components/modals/get-contact-info-modal';
import {
  bulkAddToProspectLeadAPI,
  bulkAddToSequenceLeadAPI,
  bulkExportLeadAPI,
  bulkRevealLeadAPI,
  exportAllLeadsAPI,
  getLeads,
  getSavedLeads,
  getTagsToLeads,
} from '../../helpers/leads.api';
import toaster, { Theme } from '../../../../shared/toaster';
import ConfirmationModalV3 from '../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { ConfirmationModalIconType } from '../../../../shared/design-system/components/atoms/confirmation-modal/enum';
import {
  BULK_ACTION_TIMEOUT,
  BulkLeadsActionType,
  LEAD_FINDER_REVEAL_LIMIT,
  LeadFinderTabKey,
} from '../../type';
import { RootState } from '../../../../store/root-reducer';
import { getPostLoadMetaRequest } from '../../../home/extra-actions';
import {
  OverlayTooltip,
  Placement,
} from '../../../../shared/design-system/components/overlay';
import { LEAD_LISTING_MESSAGES } from '../../helpers/leads-message';
import hasResource from '../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../shared/utils/access-control/enums/resource-identifiers';
import {
  getUserLeadFinderPlanName,
  getUserPlanName,
  getUserRole,
  getUserShAccountId,
  getUserTrackingId,
  leadFinderBulkOperationLimit,
} from '../../../../shared/utils/user-details';
import { AnalyticsEvents } from '../../../../shared/enums/analytics';
import { SystemErrors } from '../../../../shared/enums/system-errors';
import { usePageVisibility } from '../../../../shared/utils/hooks';
import TagAutosuggest, {
  RenderMode,
} from '../../../../shared/design-system/components/molecules/tag-autosuggest/tag-autosuggest';
import AddRemoveTagButton from '../add-remove-tag-button/add-remove-tag-button';

export default function TotalCredits({
  handleSelectAllLead,
  selectedLeads,
  leadsData,
  isBulkRevealing,
  apiParam,
  setLeadsData,
  activeTabKey,
  setIsBulkRevealing,
  setSelectedLeads,
  isSelectAllIndeterminate,
  disabledLeads,
}) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const leadCreditsState = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const [bulkTimeout, setBulkTimeout] = useState(null);

  const [leadCredits, setLeadsCredits] = useState('0');
  const [leadTags, setLeadTags] = useState([]);
  const [isLeadTagsLoading, setIsLeadTagsLoading] = useState(false);

  const [hoveredSelectAll, setHoveredSelectAll] = useState(false);

  const { t } = useTranslation();
  const [loadingBulkAction, setLoadingBulkAction] = useState({
    loadingReveal: false,
    loadingAddToProspect: false,
    loadingExport: false,
    loadingAddToSequence: false,
    loadingAddTag: false,
  });
  const [showAddToSequenceModal, setShowAddToSequenceModal] = useState<boolean>(
    false,
  );
  const [showTagsModal, setShowTagsModal] = useState<boolean>(false);
  const [tagsToAdd, setTagsToAdd] = useState([]);
  const [tagsToRemove, setTagsToRemove] = useState([]);
  const [
    CreditNotAvailableModalOpen,
    setCreditNotAvailableModalOpen,
  ] = useState<boolean>(false);
  const [
    selectedMoreThanLimitModalOpen,
    setSelectedMoreThanLimitModalOpen,
  ] = useState(false);

  const [
    getContactInfoModalOpen,
    setGetContactInfoModalOpen,
  ] = useState<boolean>(false);
  const hideAddToSequenceModal = () => {
    setShowAddToSequenceModal(false);
  };

  console.log(showTagsModal);
  const hideGetContactInfoModal = () => {
    setGetContactInfoModalOpen(false);
  };

  const hideLimitreachedModal = () => {
    setSelectedMoreThanLimitModalOpen(false);
  };

  const hideCreditNotAvailableModal = () => {
    setCreditNotAvailableModalOpen(false);
  };

  const isLeadNoRevealedPresent = selectedLeads?.some(
    (l) => !l?.isRevealed || l?.reReveal,
  );

  const isPageVisible = usePageVisibility();
  const timerIdRef = useRef(null);
  const [isPollingEnabled, setIsPollingEnabled] = useState(false);
  const [isFirstPollRequest, setIsFirstPollRequest] = useState(true);

  useEffect(() => {
    const startPolling = () => {
      timerIdRef.current = setInterval(() => {
        const shouldContinuePolling = leadsData?.profiles?.some(
          (ele) => ele.isRevealing,
        );
        console.log('shouldContinuePolling', shouldContinuePolling);
        if (!shouldContinuePolling) {
          setIsPollingEnabled(false);
          console.log('Polling failed. Stopped polling.');
        } else {
          console.log('Polling...', isFirstPollRequest);
          setIsPollingEnabled(true);
          handleFetchLeadsAfterBulkActions();
          if (isFirstPollRequest) {
            setSelectedLeads([]);
            setIsFirstPollRequest(false);
          }
        }
      }, BULK_ACTION_TIMEOUT);
    };

    const stopPolling = () => {
      setIsFirstPollRequest(true);
      clearInterval(timerIdRef.current);
    };

    console.log('polling', isFirstPollRequest, isPageVisible, isPollingEnabled);

    if (isPageVisible && isPollingEnabled) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [isPageVisible, isPollingEnabled, leadsData]);

  const isSelectedAll = () =>
    leadsData?.profiles?.every((lead) =>
      selectedLeads?.some((l) => l?.id === lead?.id),
    );
  const isIntermediateAll = () => {
    const tempArr = leadsData?.profiles?.filter((lead) =>
      selectedLeads?.some((l) => l?.id === lead?.id),
    );
    return (
      tempArr?.length + disabledLeads?.length === leadsData?.profiles?.length
    );
  };

  const availableLeadsData = () =>
    leadsData?.profiles?.filter(
      (lead) =>
        !selectedLeads?.some((l) => l?.id === lead?.id) &&
        !disabledLeads?.some((l) => l?.id === lead?.id),
    ).length;

  const handleFetchLeadsAfterBulkActions = async (enablePolling = false) => {
    try {
      let res: any = {};
      if (activeTabKey === LeadFinderTabKey.PEOPLE_TAB) {
        res = await getLeads(apiParam);
      } else {
        res = await getSavedLeads(apiParam);
      }
      if (res?.payload) {
        setLeadsData(res.payload);
        setIsBulkRevealing(false);
        dispatch(getPostLoadMetaRequest());
        if (enablePolling) {
          setIsPollingEnabled(true);
          setSelectedLeads([]);
        }
      }
    } catch (e: any) {
      toaster.error(e?.message);
    }
  };

  const handleBulkReveal = async () => {
    try {
      const leadIds = selectedLeads
        ?.filter((l) => !l?.isRevealed || l?.reReveal)
        .map((l) => l?.id);
      const tagIds = [];
      const newTags = [];

      tagsToAdd.forEach((tag) => {
        if (tag.isNew) {
          newTags.push(tag.name);
        } else {
          tagIds.push(tag.id);
        }
      });

      tagsToRemove.forEach((tag) => {
        tagIds.push(tag);
      });

      setLoadingBulkAction((prev) => ({ ...prev, loadingReveal: true }));
      setIsBulkRevealing(true);
      const bulkRevealRes = await bulkRevealLeadAPI({
        leadIds,
        tagIds,
        newTags,
      });
      if (bulkRevealRes) {
        toaster.success(bulkRevealRes?.payload?.message);
        handleFetchLeadsAfterBulkActions(true);
        setLoadingBulkAction((prev) => ({ ...prev, loadingReveal: false }));
        setGetContactInfoModalOpen(false);
        // setBulkTimeout(
        //   setTimeout(() => {
        //     handleFetchLeadsAfterBulkActions();
        //     setSelectedLeads([]);
        //   }, BULK_ACTION_TIMEOUT),
        // );
      }
    } catch (e: any) {
      setLoadingBulkAction((prev) => ({ ...prev, loadingReveal: false }));
      setGetContactInfoModalOpen(false);
      setIsBulkRevealing(false);
      toaster.error(e?.message || SystemErrors.InternalServerError, {
        theme: Theme.New,
      });
    }
  };

  const handleBulkAddToProspect = async () => {
    try {
      const leadIds = selectedLeads.map((l) => l?.id);
      setLoadingBulkAction((prev) => ({
        ...prev,
        loadingAddToProspect: true,
      }));
      setSelectedLeads([]);
      const bulkAddtoProspectRes = await bulkAddToProspectLeadAPI({
        leadIds,
      });
      if (bulkAddtoProspectRes) {
        toaster.success(bulkAddtoProspectRes?.payload?.message);
        setLoadingBulkAction((prev) => ({
          ...prev,
          loadingAddToProspect: false,
        }));
        handleFetchLeadsAfterBulkActions();
        setBulkTimeout(
          setTimeout(() => {
            handleFetchLeadsAfterBulkActions();
          }, BULK_ACTION_TIMEOUT),
        );
      }
    } catch (e) {
      setLoadingBulkAction((prev) => ({
        ...prev,
        loadingAddToProspect: false,
      }));
    }
  };

  const handleBulkExport = async () => {
    let leadIds = [];
    if (selectedLeads.length > 0) {
      leadIds = selectedLeads.map((l) => l?.id);
    }
    try {
      setLoadingBulkAction((prev) => ({ ...prev, loadingExport: true }));
      let result;
      if (leadIds.length > 0) {
        result = await bulkExportLeadAPI({ leadIds });
      } else {
        result = await exportAllLeadsAPI();
      }
      const { message, status } = result.payload;
      if (status === 0) {
        toaster.error(message, { theme: Theme.New });
      } else if (status === 2) {
        toaster.warning(message, { theme: Theme.New });
      } else {
        toaster.success(message, { theme: Theme.New });
      }
      setLoadingBulkAction((prev) => ({
        ...prev,
        loadingExport: false,
      }));
    } catch (err) {
      setLoadingBulkAction((prev) => ({
        ...prev,
        loadingExport: false,
      }));
      toaster.error('Error while exporting lead data');
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const addContactToStep = async (sequenceId, stepId) => {
    try {
      setLoadingBulkAction((prev) => ({
        ...prev,
        loadingAddToSequence: true,
      }));
      const leadIds = selectedLeads.map((l) => l?.id);
      const res = await bulkAddToSequenceLeadAPI({
        leadIds,
        sequenceId,
        stepId,
      });
      toaster.success(res?.payload?.message);
      setLoadingBulkAction((prev) => ({
        ...prev,
        loadingAddToSequence: false,
      }));
      handleFetchLeadsAfterBulkActions();
      setSelectedLeads([]);
      setShowAddToSequenceModal(false);
      setBulkTimeout(
        setTimeout(() => {
          handleFetchLeadsAfterBulkActions();
        }, BULK_ACTION_TIMEOUT),
      );
    } catch (e: any) {
      toaster.error(e?.message);
      setShowAddToSequenceModal(false);
      setLoadingBulkAction((prev) => ({
        ...prev,
        loadingAddToSequence: false,
      }));
    }
  };

  // eslint-disable-next-line consistent-return
  const handleBulkAction = (actionType) => {
    let leadIds = [];
    switch (actionType) {
      case BulkLeadsActionType.REVEAL:
        leadIds = selectedLeads
          ?.filter((l) => !l?.isRevealed || l?.reReveal)
          .map((l) => l?.id);
        if (leadIds?.length > leadFinderBulkOperationLimit()) {
          return setSelectedMoreThanLimitModalOpen(true);
        } else if (Number(leadCredits) < leadIds.length) {
          setCreditNotAvailableModalOpen(true);
          // Lead Finder Tripped Feature Event
          window.analytics?.track({
            userId: getUserTrackingId(),
            event: AnalyticsEvents.TrippedFeature,
            properties: {
              shAccountId: getUserShAccountId(),
              current_plan: getUserPlanName(),
              role: getUserRole(),
              'LF Plan': getUserLeadFinderPlanName(),
              feature_type: 'Lead Finder',
            },
          });
        } else {
          setGetContactInfoModalOpen(true);
        }
        break;
      case BulkLeadsActionType.ADD_TO_PROSPECT:
        leadIds = selectedLeads.map((l) => l?.id);
        if (leadIds?.length > leadFinderBulkOperationLimit()) {
          return setSelectedMoreThanLimitModalOpen(true);
        } else {
          handleBulkAddToProspect();
        }
        break;
      case BulkLeadsActionType.ADD_TO_SEQUENCE:
        leadIds = selectedLeads.map((l) => l?.id);
        if (leadIds?.length > leadFinderBulkOperationLimit()) {
          return setSelectedMoreThanLimitModalOpen(true);
        } else {
          setShowAddToSequenceModal(true);
        }
        break;
      case BulkLeadsActionType.EXPORT:
        leadIds = selectedLeads.map((l) => l?.id);
        if (leadIds?.length > leadFinderBulkOperationLimit()) {
          return setSelectedMoreThanLimitModalOpen(true);
        } else {
          handleBulkExport();
        }
        break;
    }
  };

  const upgradePlanhandler = () => {
    history.push({
      pathname: '/settings/billing/subscriptions',
    });
  };

  useEffect(() => {
    setLeadsCredits(
      leadCreditsState?.home?.getPostLoadMetaResponse
        ?.accountUsageQuotaRemaining?.['LEAD.REVEAL'],
    );
  }, [leadCreditsState, leadsData]);

  const getTooltip = (text: string) => (
    <Tooltip
      id="popover-basic"
      className="bs-tooltip-inner checkbox-tooltip-selected"
    >
      {text}
    </Tooltip>
  );

  useEffect(
    () => () => {
      clearTimeout(bulkTimeout);
    },
    [bulkTimeout],
  );

  const handleFetchLeadTags = async () => {
    try {
      setIsLeadTagsLoading(true);
      const response = await getTagsToLeads();
      setLeadTags(response.payload);
      setIsLeadTagsLoading(false);
    } catch (error) {
      toaster.error('Error fetching lead tags');
      setIsLeadTagsLoading(false);
    }
  };

  const handleAddTag = (updatedTags) => {
    setLeadTags(updatedTags);
  };

  const handleRemoveTag = (updatedTags) => {
    setLeadTags(updatedTags);
  };

  useEffect(() => {
    if (getContactInfoModalOpen === true) {
      handleFetchLeadTags();
    }
  }, [getContactInfoModalOpen]);

  return (
    <>
      <div className="leads-filters pl-4">
        {leadsData && leadsData?.profiles?.length > 0 && location?.search && (
          <div className="d-flex align-items-center">
            <span
              className="d-flex"
              onMouseEnter={() => setHoveredSelectAll(true)}
              onMouseLeave={() => setHoveredSelectAll(false)}
            >
              <OverlayTrigger
                placement={Placement.Bottom}
                overlay={getTooltip(
                  LEAD_LISTING_MESSAGES.max_limit_reached_tooltip_msg,
                )}
                show={
                  hoveredSelectAll &&
                  selectedLeads?.length + Number(queryParams.get('take')) >
                    leadFinderBulkOperationLimit() &&
                  !isIntermediateAll() &&
                  leadFinderBulkOperationLimit() - selectedLeads?.length <
                    availableLeadsData()
                }
              >
                <Checkbox
                  checked={isSelectedAll()}
                  className="checkbox-custome"
                  intermediate={isSelectAllIndeterminate}
                  disabled={
                    selectedLeads?.length + Number(queryParams.get('take')) >
                      leadFinderBulkOperationLimit() &&
                    !isIntermediateAll() &&
                    leadFinderBulkOperationLimit() - selectedLeads?.length <
                      availableLeadsData()
                  }
                  onChange={() => {
                    if (isSelectAllIndeterminate) {
                      // Deselect all leads on the current page when the checkbox is in an indeterminate state
                      const leadsOnCurrentPage = leadsData?.profiles || [];
                      setSelectedLeads((prevLeads) => {
                        const updatedLeads = prevLeads.filter(
                          (lead) =>
                            !leadsOnCurrentPage?.some(
                              (currentLead) => currentLead?.id === lead?.id,
                            ),
                        );
                        return updatedLeads;
                      });
                    } else {
                      // Otherwise, proceed with the select all logic
                      handleSelectAllLead(leadsData?.profiles);
                    }
                  }}
                />
              </OverlayTrigger>
              <span className="checkbox-lable pl-3">
                {selectedLeads?.length > 0 &&
                  `${selectedLeads?.length} Selected`}
                {selectedLeads?.length === 0 && `Select all`}
              </span>
            </span>

            <div className="d-flex gap-2 lead-button-container">
              {isLeadNoRevealedPresent && (
                <Button
                  variant="secondary"
                  disabled={selectedLeads?.length === 0 || isBulkRevealing}
                  isLoading={isBulkRevealing}
                  loadingText="Get Contact Info"
                  onClick={() => handleBulkAction(BulkLeadsActionType.REVEAL)}
                  className="btn-leads-user-details btn-custom-secondary"
                >
                  <span className="filter-lable">Get Contact Info</span>
                </Button>
              )}
              {!isLeadNoRevealedPresent && (
                <Button
                  variant="secondary"
                  disabled={selectedLeads?.length === 0}
                  isLoading={loadingBulkAction?.loadingAddToProspect}
                  className="btn-leads-user-details btn-custom-secondary"
                  onClick={() => {
                    if (hasResource(ResourceIdentifiers.CONTACTS_IMPORT)) {
                      handleBulkAction(BulkLeadsActionType.ADD_TO_PROSPECT);
                    }
                  }}
                >
                  <UserList className="icon-color" />
                  <span className="filter-lable">Save as Prospect</span>
                </Button>
              )}
              {!isLeadNoRevealedPresent && (
                <Button
                  variant="secondary"
                  disabled={selectedLeads?.length === 0}
                  isLoading={loadingBulkAction.loadingAddToSequence}
                  className="btn-leads-user-details btn-custom-secondary"
                  onClick={() =>
                    handleBulkAction(BulkLeadsActionType.ADD_TO_SEQUENCE)
                  }
                >
                  <Send className="icon-color" />
                  <span className="filter-lable">Add to Sequence</span>
                </Button>
              )}
              {!isLeadNoRevealedPresent &&
                activeTabKey === LeadFinderTabKey.SAVED_TAB && (
                  <>
                    <AddRemoveTagButton
                      handleAddTag={handleAddTag}
                      handleRemoveTag={handleRemoveTag}
                      show={true}
                      disabled={selectedLeads?.length === 0}
                      leadTags={leadTags}
                      selectedLeads={selectedLeads}
                    />
                  </>
                )}
            </div>
          </div>
        )}
        <div
          className={`total-credits ${
            leadsData && leadsData?.profiles?.length > 0 && location?.search
              ? ''
              : 'ml-auto'
          }`}
        >
          {!isLeadNoRevealedPresent &&
            activeTabKey === LeadFinderTabKey.SAVED_TAB && (
              <OverlayTooltip
                text={
                  selectedLeads?.length === 0
                    ? 'Export all saved leads'
                    : 'Export selected leads'
                }
                key="lead-export"
                placement={Placement.Bottom}
              >
                <Button
                  variant="secondary"
                  isLoading={loadingBulkAction?.loadingExport}
                  className="btn-leads-user-details export-button btn-custom-secondary mr-2"
                  onClick={() => handleBulkAction(BulkLeadsActionType.EXPORT)}
                >
                  <Download className="icon-color" />
                  <span className="filter-lable">Export</span>
                </Button>
              </OverlayTooltip>
            )}
          <div
            className="credit-number cursor-pointer"
            onClick={() =>
              history.push({
                pathname: '/settings/billing/upgrade-plan/lead-finder',
              })
            }
          >
            <Diamond className="diamond-icon" />
            <p>
              {leadCredits ?? 0}{' '}
              {[0, 1].includes(Number(leadCredits)) ? 'Credit' : 'Credits'}
            </p>
          </div>
        </div>
      </div>
      {showAddToSequenceModal && (
        <AddToSequenceModal
          show={showAddToSequenceModal}
          onHide={hideAddToSequenceModal}
          addToSequence={addContactToStep}
          isSubscribed={true}
        />
      )}

      {getContactInfoModalOpen && (
        <GetContactDetail
          show={getContactInfoModalOpen}
          onHide={hideGetContactInfoModal}
          onDelete={() => handleBulkReveal()}
          isLoading={loadingBulkAction?.loadingReveal}
          title="Want to reveal multiple leads?"
          icon={
            <AlertCircle width={23} height={23} className="orange-txt-14" />
          }
          showCloseIcon={false}
          content={
            <>
              <div className="mt-2">
                <span className="regular-2">
                  {' '}
                  {
                    selectedLeads?.filter((l) => !l?.isRevealed || l?.reReveal)
                      .length
                  }{' '}
                  leads are selected. Revealing them might take some time. You
                  will be notified once all of them are revealed.
                </span>
              </div>
              <div className="second-line-get-contact-modal get-contact-modal-tag">
                <span className="regular-2">
                  You can add a custom tag to filter these leads.
                </span>
              </div>

              <TagAutosuggest
                tags={leadTags}
                selectedProspects={selectedLeads}
                tagsToAdd={tagsToAdd}
                setTagsToAdd={setTagsToAdd}
                tagsToRemove={tagsToRemove}
                setTagsToRemove={setTagsToRemove}
                renderMode={RenderMode.Block}
                hideAlreadyAddedTags={true}
                hideNoTagsMessage={true}
              />
            </>
          }
          submitButtonText="Continue"
        />
      )}
      {selectedMoreThanLimitModalOpen && (
        <ConfirmationModalV3
          show={selectedMoreThanLimitModalOpen}
          title="Selected More than limit!"
          iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
          contents={[
            `Please select less then ${leadFinderBulkOperationLimit()} leads to proceed!`,
          ]}
          onClose={hideLimitreachedModal}
          onSubmit={hideLimitreachedModal}
          hideCancelButton
          submitButtonText="Ok"
        />
      )}

      {CreditNotAvailableModalOpen && (
        <GetContactDetail
          show={CreditNotAvailableModalOpen}
          onHide={hideCreditNotAvailableModal}
          onDelete={upgradePlanhandler}
          title="Buy credits to see contact information"
          icon={<UserList width={18} height={18} className="orange-txt-14" />}
          content={
            <>
              <div className="mt-2">
                <span className="regular-2 blue-txt-15">
                  You don't have enough lead finder credits to view contact
                  information for selected leads.
                </span>
              </div>
              <div className="second-line-get-contact-modal">
                <span className="regular-2">
                  Please add more credits or upgrade your plan to continue.
                </span>
              </div>
            </>
          }
          submitButtonText="Upgrade"
        />
      )}
    </>
  );
}
