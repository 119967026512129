/* eslint-disable @typescript-eslint/naming-convention */
import { RouteComponentProps } from 'react-router';
import { ProspectsFilters } from '../../shared/types/prospects-filters';
import { ContactStatusType, SortOrder } from '../sequence/enums';

export type IProps = RouteComponentProps;

export type ProspectParams = {
  email?: string;
  search?: string;
  sequenceId?: number;
  pageSize: number;
  pageNum: number;
  tags?: string[];
  status?: ContactStatusType[];
  sortBy?: ProspectSortBy;
  sortOrder?: SortOrder;
  isSequenceNone?: boolean;
};

export type ProspectFilters = {
  sortBy: ProspectSortBy;
  sortOrder: SortOrder;
};

export type ProspectCountParams = {
  email?: string;
  search?: string;
  sequenceId?: number;
};

export type ProspectPagination = {
  pageNum: number;
  pageSize: number;
};

export type AddToStepParams = {
  stepId?: number;
  sequenceId: string;
  contactIds: number[];
};

export type removeProspectParam = {
  contactId: number;
  sequenceIds: number[];
};

export type markAsRepliedParam = {
  contactId: number;
  stepId: number;
};

export type removeFromSequenceParam = {
  contactIds: number[];
  sequenceId: number;
};

export type SaveContactAttributesPayload = {
  contactId: number;
  attributes: [];
};

export type saveContactDealValuePayload = {
  sequenceId: number;
  attributes: [];
};

export type AddProspectManuallyPayload = {
  attributes: any[];
};

export type AddTagsToProspectsPayload = {
  ids: number[];
  prospects: number[];
  newTags: string[];
};

export type RemoveTagsFromProspectsPayload = {
  tagId: number;
  prospects: number[];
}[];

type BulkActionFilters = ProspectsFilters & {
  sortBy?: ProspectSortBy;
  sortOrder?: SortOrder;
  search?: string;
  isSequenceNone?: boolean;
};

export type BulkActionPayload = {
  deSelectedContactIds?: number[];
  contactsFilter?: BulkActionFilters;
  stepId?: number;
  sequenceId?: string;
  ids?: number[];
  newTags?: string[];
  tagIds?: number[];
};

export enum ProspectSortBy {
  Owner = 'owner',
};

export type SelectAllContacts = {
  isAllProspectsSelected?: boolean;
  contactsFilter?: BulkActionFilters;
  deSelectedContactIds?: any[];
};

export type deleteSecondaryEmailParams = {
  prospectInfoId: number;
  contactId: number;
};

export type changePrimaryEmailInProspectPayload = {
  primaryEmailProspectInfoId: number;
  contactId: number;
}

export type deleteSecondaryPhoneParams = {
  prospectInfoId: number;
  contactId: number;
}