import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Accordion, Pills, SkeletonLoading } from '@saleshandy/design-system';
import { Minus, Plus } from '@saleshandy/icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import KeywordInputComponent from '../keyword-search-input/keyword-search-input';
import { Filter } from '@saleshandy/icons';
import Tag from '../../../../shared/design-system/components/tag/tag';
import { useSavedLeadFilter } from '../hooks/useLeadSavedFilter';
import { FILTER_SAVED_LIST } from '../hooks/useLeadSavedFilter';
import { Placement } from '../../../../shared/design-system/components/overlay';
import { FilterComponentType, SavedTabFilters } from '../../type';
import TagSavedFilters from './components/tags';
import { DateTime } from 'luxon';
import { getTagsToLeads } from '../../helpers/leads.api';
import DateFilter from '../../../../shared/design-system/components/molecules/prospects-filter/date-filter';
import { dateToString } from '../../../../shared/functions/date';
import { useLocation } from 'react-router-dom';

const SavedTab: React.FC = (activeTabKey) => {
  const location = useLocation();
  const keywordInputRef: any = useRef();

  const {
    handleQuery,
    handleGetAllKeysByFilterFromQuery,
    handleIndividualTag,
    handleDefaultValues,
  } = useSavedLeadFilter(activeTabKey);

  const [selectedFilter, setSelectedFilter] = useState<any>([]);
  const [savedFilters, setSavedFilters] = useState<SavedTabFilters>(
    handleDefaultValues(),
  );
  const [leadTags, setLeadTags] = useState([]);
  const [isLoadingLeadTags, setIsLoadingLeadTags] = useState(false);

  const overlayRef = useRef(null);
  const accordionKey = 3830;
  const [showTagTooltip, setShowTagTooltip] = useState<any>(null);

  const [show, setShow] = useState(false);

  const onSelectFilter = (id, filter) => {
    setSelectedFilter((prevSelectedFilter) => {
      const index = prevSelectedFilter.findIndex((filter) => filter.id === id);
      if (index === -1) {
        return [
          ...prevSelectedFilter,
          {
            id,
            filter,
          },
        ];
      }
      return prevSelectedFilter.filter((filter) => filter.id !== id);
    });
  };

  const isSelectedFilter = useMemo(() => {
    return (filter) => selectedFilter.some((f) => f.id === filter.id);
  }, [selectedFilter]);

  const handleDeleteTagFilter = (filter) => {
    if (filter.label === 'Date') {
      setSavedFilters((prevFilters) => ({
        ...prevFilters,
        createdDate: [],
      }));
    } else if (filter.label === 'Tags') {
      setSavedFilters((prevFilters) => ({
        ...prevFilters,
        tags: [],
      }));
    }

    handleQuery({
      filterKey: filter.key,
      filterValue: {
        tags: [],
        createdDate: [],
      },
      filterType:
        filter.key === 'createdDate'
          ? FilterComponentType.SAVED_LEAD_DATE
          : FilterComponentType.TAG_CHECKBOX,
    });
  };

  useEffect(() => {
    if (keywordInputRef.current) {
      keywordInputRef.current.focus();
    }
    return () => {
      setSelectedFilter([]);
    };
  }, []);

  const getLebelTooltip = (text: string) => (
    <Tooltip id="popover-basic" className="bs-tooltip-inner">
      {text}
    </Tooltip>
  );

  useEffect(() => {}, [savedFilters]);

  const fetchTags = async () => {
    setIsLoadingLeadTags(true);
    try {
      const response = await getTagsToLeads();
      setLeadTags(response?.payload || []);
    } catch (error) {
      console.error('Error fetching tags:', error);
    } finally {
      setIsLoadingLeadTags(false);
    }
  };

  useEffect(() => {
    fetchTags();
  }, [location]);

  const handleDateChange = (createdDate) => {
    if (createdDate?.length === 0) {
      setSavedFilters({
        ...savedFilters,
        createdDate: [],
      });
      handleQuery({
        filterKey: 'createdDate',
        filterValue: {
          createdDate: [],
        },
        filterType: FilterComponentType.SAVED_LEAD_DATE,
      });
      return;
    }
    const startDate = new Date(createdDate[0]);
    const endDate = new Date(createdDate[1]);
    const formattedDates = [dateToString(startDate), dateToString(endDate)];
    const dateTime = [
      DateTime.fromJSDate(createdDate[0]),
      DateTime.fromJSDate(createdDate[1]),
    ];
    setSavedFilters({
      ...savedFilters,
      createdDate: dateTime,
    });
    setShow(false);

    handleQuery({
      filterKey: 'createdDate',
      filterValue: {
        createdDate: [
          dateTime[0].toFormat('dd MMM, yy'),
          dateTime[1].toFormat('dd MMM, yy'),
        ],
      },
      filterType: FilterComponentType.SAVED_LEAD_DATE,
    });
  };

  return (
    <>
      <KeywordInputComponent
        handleQuery={handleQuery}
        keywordInputRef={keywordInputRef}
      />
      <h3 className="leads-filter">
        <div>
          <Filter className="sh-filter" />
          Filter
        </div>
      </h3>
      <div>
        <div
          key={accordionKey}
          ref={overlayRef}
          className="people-tab-accordian"
        >
          <Accordion allowMultipleExpanded key={accordionKey} allowZeroExpanded>
            {FILTER_SAVED_LIST &&
              FILTER_SAVED_LIST.map((filter: any) => {
                const allTags = handleGetAllKeysByFilterFromQuery(filter);
                return (
                  <Accordion.Item
                    key={filter.id}
                    uuid={filter.id}
                    dangerouslySetExpanded={selectedFilter?.some(
                      (f) => f?.id === filter?.id,
                    )}
                    className={`${
                      isSelectedFilter(filter)
                        ? 'accordion__item_selected'
                        : 'accordion__item'
                    }`}
                  >
                    <Accordion.ItemHeading
                      onClick={() => onSelectFilter(filter.id, filter)}
                      className={`${
                        allTags.size > 0 && !isSelectedFilter(filter)
                          ? 'accordion-item-heading-tag'
                          : 'accordion-item-heading'
                      } ${allTags.size ? 'accordion-item-selected-tag' : ''}`}
                    >
                      <Accordion.ItemButton className="::before:none w-100">
                        <div className="w-100 accordian-label">
                          <div className="d-flex">
                            <div className="icon">{filter.icon}</div>
                            <div className="label">{filter?.label}</div>
                          </div>
                          <div className="d-flex align-items-center">
                            {(filter?.label === 'Tags'
                              ? savedFilters.tags?.length
                              : savedFilters.createdDate?.length) > 0 && (
                              <Tag
                                value={
                                  filter?.label === 'Tags'
                                    ? savedFilters.tags.length
                                    : 1
                                }
                                variant={Tag.Variant.Remove}
                                className="bg-white rounded-pill"
                                onClick={(e) => {
                                  e?.stopPropagation();
                                  handleDeleteTagFilter(filter);
                                }}
                              />
                            )}
                            <div className="d-flex align-items-center justify-content-end">
                              <Accordion.ItemState>
                                {({ expanded }) =>
                                  expanded ? (
                                    <Minus className="accordion-minus-icon" />
                                  ) : (
                                    <Plus className="accordion-plus-icon" />
                                  )
                                }
                              </Accordion.ItemState>
                            </div>
                          </div>
                        </div>
                        {allTags.size > 0 && !isSelectedFilter(filter) && (
                          <div className="filter-applied-collapse">
                            {Array.from(allTags)
                              .slice(0, 5)
                              .map((tag: any) => (
                                <span
                                  onMouseEnter={() =>
                                    setShowTagTooltip(tag?.label)
                                  }
                                  onMouseLeave={() => setShowTagTooltip(null)}
                                >
                                  <OverlayTrigger
                                    placement={Placement.BottomStart}
                                    overlay={getLebelTooltip(tag?.label)}
                                    show={
                                      showTagTooltip === tag?.label &&
                                      tag?.label?.length > 15
                                    }
                                  >
                                    <Tag
                                      key={tag?.label}
                                      value={
                                        tag?.label.length > 15
                                          ? `${tag?.label.substring(0, 15)}..`
                                          : tag?.label
                                      }
                                      variant={Tag.Variant.Remove}
                                      className="filter-tag"
                                      onClick={(e) => {
                                        e?.stopPropagation();
                                        handleIndividualTag(tag);
                                      }}
                                    />
                                  </OverlayTrigger>
                                </span>
                              ))}
                            {Array.from(allTags)?.length > 5 && (
                              <Tag
                                value={
                                  <div className="d-flex align-items-center">
                                    <Plus
                                      className="gray-txt-15 mr-1"
                                      width={13}
                                      height={13}
                                    />
                                    <p className="mb-0">
                                      {Array.from(allTags)?.length - 5}
                                    </p>
                                  </div>
                                }
                                className="filter-tag more-tag"
                              />
                            )}
                          </div>
                        )}
                      </Accordion.ItemButton>
                    </Accordion.ItemHeading>

                    <Accordion.ItemPanel>
                      {filter?.type?.map((subFilter) => (
                        <React.Fragment
                          key={`subfilter-${filter?.id}-${subFilter.id}`}
                        >
                          {subFilter?.value ===
                            FilterComponentType.TAG_CHECKBOX && (
                            <>
                              <div>
                                {isLoadingLeadTags ? (
                                  <SkeletonLoading height={32} width={235} />
                                ) : (
                                  <TagSavedFilters
                                    leadTags={leadTags}
                                    leadTagsIds={savedFilters.tags}
                                    onLeadTagsIdsChange={(leadTagsIds) => {
                                      setSavedFilters((prevFilters) => {
                                        return {
                                          ...prevFilters,
                                          tags: leadTagsIds,
                                        };
                                      });
                                      handleQuery({
                                        filterKey: 'tags',
                                        filterValue: {
                                          tags: leadTagsIds,
                                        },
                                        filterType:
                                          FilterComponentType.TAG_CHECKBOX,
                                      });
                                    }}
                                  />
                                )}

                                {savedFilters.tags && (
                                  <div className="pill">
                                    {savedFilters.tags.map((tagId) => {
                                      const tagValue = leadTags.find(
                                        (ele) => ele.id === tagId,
                                      );
                                      const handlePillClose = (idToRemove) => {
                                        const updatedTags = savedFilters.tags.filter(
                                          (tag) => tag !== idToRemove,
                                        );
                                        setSavedFilters((prevFilters) => ({
                                          ...prevFilters,
                                          tags: updatedTags,
                                        }));
                                        handleQuery({
                                          filterKey: 'tags',
                                          filterValue: {
                                            tags: updatedTags,
                                          },
                                          filterType:
                                            FilterComponentType.TAG_CHECKBOX,
                                        });
                                      };

                                      // Render the 'name' property of the tagValue object
                                      return (
                                        <Pills
                                          theme="outline"
                                          size="md"
                                          className="pill-saved-filter pills-container"
                                          label={tagValue?.name}
                                          showCloseIcon={true}
                                          onClose={() => handlePillClose(tagId)}
                                          key={tagId}
                                        />
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </>
                          )}

                          {subFilter?.value ===
                            FilterComponentType.SAVED_LEAD_DATE && (
                            <>
                              <div className="lead-filter-date-piker">
                                <DateFilter
                                  onSelect={handleDateChange}
                                  values={[]}
                                />
                              </div>

                              {savedFilters?.createdDate?.length > 0 && (
                                <Pills
                                  theme="outline"
                                  size="md"
                                  className="pill-saved-filter pills-container"
                                  label={`${savedFilters?.createdDate?.[0].toFormat(
                                    'dd MMM, yy',
                                  )} - ${savedFilters?.createdDate?.[1].toFormat(
                                    'dd MMM, yy',
                                  )}`}
                                  showCloseIcon={true}
                                  onClose={() => {
                                    handleQuery({
                                      filterKey:
                                        FilterComponentType.SAVED_LEAD_DATE,
                                      filterValue: {
                                        createdDate: [],
                                      },
                                      filterType:
                                        FilterComponentType.SAVED_LEAD_DATE,
                                    });
                                    setSavedFilters((prevFilters) => ({
                                      ...prevFilters,
                                      createdDate: [],
                                    }));
                                  }}
                                />
                              )}
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </Accordion.ItemPanel>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default SavedTab;
