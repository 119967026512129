import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SHTag from '../../../../shared/components/images/sh-tag';
import { Button } from '@saleshandy/design-system';
import Modal from '../../../../shared/design-system/components/atoms/modal';
import TagAutosuggest from '../../../../shared/design-system/components/molecules/tag-autosuggest';
import { RenderMode } from '../../../../shared/design-system/components/molecules/tag-autosuggest/tag-autosuggest';
import { Tag } from '@saleshandy/icons';
import { AddTagsToLeadsPayload } from '../../type';
import { map } from 'lodash';
import {
  addTagsToLeads,
  getTagsToLeads,
  removeTagsToLeads,
} from '../../helpers/leads.api';
import { redirectWithoutRefresh } from '../../../../shared/utils';

const AddRemoveTagButton = ({
  handleAddTag,
  handleRemoveTag,
  disabled = false,
  show,
  leadTags,
  selectedLeads,
}: {
  handleAddTag: (tags: any[]) => void;
  handleRemoveTag: (tags: any[]) => void;
  isDropdownFlow?: boolean;
  show;
  disabled?: boolean;
  leadTags: any[];
  selectedLeads: any[];
}) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [tagsToAdd, setTagsToAdd] = useState([]);
  const [tagsToRemove, setTagsToRemove] = useState([]);
  const [tags, setTags] = useState([]);
  const [isRemoveMode, setIsRemoveMode] = useState(false);

  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const closeDropdown = () => setShowDropdown(false);
  const closeModal = () => setShowModal(false);

  const openAddTagModal = () => {
    closeDropdown();
    setModalTitle('Add Tags');
    setIsRemoveMode(false);
    setShowModal(true);
    setTagsToAdd([]);
  };

  const openRemoveTagModal = () => {
    closeDropdown();
    setModalTitle('Remove Tags');
    setIsRemoveMode(true);
    setShowModal(true);
    setTagsToRemove([]);
  };

  const handleSubmitModal = async () => {
    const payload: AddTagsToLeadsPayload = {
      leads: map(selectedLeads, 'id'),
      tagIds: [],
      newTags: [],
    };

    if (!isRemoveMode) {
      tagsToAdd.forEach((tag) => {
        if (tag.isNew) {
          payload.newTags.push(tag.name);
        } else {
          payload.tagIds.push(tag.id);
        }
      });

      try {
        await addTagsToLeads(payload);
        handleAddTag(tagsToAdd);

        redirectWithoutRefresh('/leads?start=1&take=25#saved');
      } catch (error) {
        console.error('Failed to add tags:', error);
      }
    } else {
      tagsToRemove.forEach((tag) => {
        payload.tagIds.push(tag);
      });

      try {
        await removeTagsToLeads({
          tagIds: payload.tagIds,
          leads: payload.leads,
        });
        handleRemoveTag(tagsToRemove);
        redirectWithoutRefresh('/leads?start=1&take=25#saved');
      } catch (error) {
        console.error('Failed to remove tags:', error);
      }
    }

    try {
      const updatedTags = await getTagsToLeads();
      setTags(updatedTags.payload);
    } catch (error) {
      console.error('Failed to fetch updated tags:', error);
    }

    closeModal();
  };

  return (
    <>
      <div className="d-flex align-items-center pointer">
        <Button
          className="tag-button"
          onClick={toggleDropdown}
          disabled={disabled}
        >
          <Tag />
          <span>Tags</span>
        </Button>
      </div>

      {showDropdown && (
        <div className="tag-dropdown-view">
          <ul className="dropdown-container">
            <li className="menu-item">
              <div
                className="d-flex px-2 export-cursor-pointer dropdown"
                onClick={openAddTagModal}
              >
                <div className="p-text-tag">
                  <p className="tag-list-font menu-text">Add Tags</p>
                </div>
              </div>
            </li>
            <li className="menu-item">
              <div
                className="d-flex px-2 export-cursor-pointer dropdown"
                onClick={openRemoveTagModal}
              >
                <div className="p-text-tag remove-tag-modal">
                  <p className="tag-list-font menu-text">Remove Tags</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}

      <Modal
        show={showModal}
        className="add-remove-tag-modal"
        titleContent={
          <div className="d-flex modal-header-title">
            <SHTag />
            <div className="pl-2">{modalTitle}</div>
          </div>
        }
        onClose={closeModal}
        showCloseIcon={false}
        submitButtonText={t('labels.apply')}
        onSubmit={handleSubmitModal}
        backdrop="static"
        extraModalProps={{
          enforceFocus: false,
          'aria-labelledby': 'contained-modal-title-vcenter',
        }}
      >
        <div
          className={`overflow-hidden saved-tab-tags ${
            isRemoveMode && 'remove-tag-modal'
          }`}
        >
          <TagAutosuggest
            selectedProspects={selectedLeads}
            tags={leadTags}
            tagsToAdd={tagsToAdd}
            setTagsToAdd={setTagsToAdd}
            tagsToRemove={tagsToRemove}
            setTagsToRemove={setTagsToRemove}
            renderMode={RenderMode.Inline}
            hideAlreadyAddedTags={isRemoveMode ? false : true}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddRemoveTagButton;
